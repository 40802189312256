import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import { Grid, Typography, Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { ButtonDegrade, CardElevation, CardProgres, Oferta } from '@findep/mf-landings-core';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import CircularProgress from '@material-ui/core/CircularProgress';
import ModalJumio from '../elements/ModalJumio.compilable'
import CarouselImg from '../img/CarouselImg.compilable'

import { sortBy, uniqBy, reverse } from 'lodash'

import { navigate } from "../../helpers/queryNavigate"

import { TubeService } from '../../services/LandingV4/tube'

import formatNumber from '../../helpers/formatNumber'
import jumioRedirect from '../../helpers/jumioRedirect'
import {
    getTaskIcon,
    getTaskStatusIcon,
    getTaskUrl,
    getTaskActionText,
    getSpecialStatusCase,
    getSpecialStatusCaseFormer,
    getSpecialStatusCasePensionados,
    getOrderArray,
    getTaskUrlFormer
} from '../../helpers/tubeTask'
function TuProceso({ pageContext }) {
    // const { palette } = useTheme()
    const [tasks, setTasks] = useState([])
    const [creditApplicationId, setId] = useState('')
    const [flujoState, setFlujo] = useState({})
    const [status, setStatus] = useState('')
    const [errorService, setErrorService] = useState(false)
    const [former, setFormer] = useState(false)
    const [pensionados, setPensionados] = useState(false)
    const [datos, setDatos] = useState({})
    const [errorCode, setErrorCode] = useState()
    const [modal, setModal] = useState(false)

    const closeModal = (valor) => {
        setModal(valor)
        jumioRedirect()
    }

    useEffect(() => {
        const fetch = async () => {
            let params = new URLSearchParams(window.location.search);
            var errorCode = params.get('errorCode');

            if (errorCode !== null) {
                setErrorCode(errorCode)
                setModal(true)
            }

            let urlPathname = window.location.pathname

            if (urlPathname.indexOf('former') >= 0 || urlPathname.indexOf('revolvente') >= 0) {
                setFormer(true)
            }
            if (urlPathname.indexOf('pensionados') >= 0) {
                setPensionados(true)
            }

            let flujo = JSON.parse(sessionStorage.getItem('flujo')) || {}
            let id = flujo?.creditApplicationId
            let Tube = new TubeService(id)
            const { data } = await Tube.send()

            setDatos(data)

            const { forceRedirect } = data
            if (forceRedirect !== null && forceRedirect !== undefined) {
                navigate(forceRedirect)
            }

            if (!flujo.creditApplicationId) {
                flujo.creditApplicationId = id
            }

            const uniq = sortBy(uniqBy(data.tasks, (obj) => obj.kind), ['id']).map(el => {
                if (former) {
                    const status = getSpecialStatusCaseFormer(
                        el.status, el.kind) || (['new', 'needscorrection'].includes(el.status?.toLowerCase())
                            ?
                            flujo.tubo?.[el.kind]?.toLowerCase() || el.status?.toLowerCase()
                            :
                            el.status?.toLowerCase())
                    return {
                        ...el,
                        status
                    }
                }else if (pensionados) {
                    const status = getSpecialStatusCasePensionados(el.status, el.kind) || (['new', 'needscorrection'].includes(el.status?.toLowerCase()) ? flujo.tubo?.[el.kind]?.toLowerCase() || el.status?.toLowerCase() : el.status?.toLowerCase())
                    return {
                        ...el,
                        status
                    }
                } 
                else {
                    const status = getSpecialStatusCase(
                        el.status, el.kind) || (['new', 'needscorrection'].includes(el.status?.toLowerCase())
                            ?
                            flujo.tubo?.[el.kind]?.toLowerCase() || el.status?.toLowerCase()
                            :
                            el.status?.toLowerCase())
                    return {
                        ...el,
                        status
                    }
                }
            })

            const preOferta = {
                oferta: {
                    payment: data.payment,
                    amount: data.amount,
                    period: data.paymentPeriod,
                    terms: data.terms,
                    nextPayment: data.nextPayment,
                }
            }

            flujo.preOferta = flujo.preOferta || preOferta

            flujo.preOferta.oferta = { ...flujo.preOferta.oferta, ...preOferta.oferta }

            urlPathname.indexOf('former') >= 0 ? setTasks(getOrderArray(uniq)) : setTasks(uniq)

            setId(id)
            setFlujo(flujo)
            setStatus(data.status)

            sessionStorage.setItem('flujo', JSON.stringify(flujo))
        }

        fetch()
            .catch((e) => {
                console.error(e)
                setErrorService(true)
            })

        if (status?.toUpperCase() === 'APPROVED') {
            navigate(pageContext?.next?.path)
        } else if (status?.toUpperCase() === 'REJECTED' || status?.toUpperCase() === 'CANCELLED') {
            navigate(pageContext.errorPath)
        }
    }, [former, pensionados])

    useEffect(() => {
        if (status?.toUpperCase() === 'APPROVED') {
            navigate(pageContext?.next?.path)
        } else if (status?.toUpperCase() === 'REJECTED' || status?.toUpperCase() === 'CANCELLED') {
            navigate(pageContext.errorPath)
        }
    }, [status])

    const TextHeader = styled.div`
        color: primary;
        text-align: center;
        font-weight: bold;
        font-size: 1.2em;
    `
    const ImageContainer = styled.div`
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        margin-left: auto;
        margin-right: auto;
        height: 280px;
        width: 300px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-content: center;
    `
    const Padding = styled.div`
        padding: 1rem;
    `

    const nextPage = () => {
        let navigateUrl

        switch (status?.toUpperCase()) {
            case 'APPROVED':
                navigateUrl = pageContext?.next?.path
                break
            case 'REJECTED':
                navigateUrl = pageContext.errorPath
                break
        }

        navigate(navigateUrl)
    }

    const props = [
        { icono: ArrowForwardIcon, titulo: 'Monto:', texto: `${formatNumber(flujoState ? flujoState.preOferta ? flujoState.preOferta.oferta.amount : 0 : 0)}` },
        { icono: ArrowForwardIcon, titulo: 'Pago:', texto: `${formatNumber(flujoState ? flujoState.preOferta ? flujoState.preOferta.oferta.payment : 0 : 0)}` },
    ]

    return (
        <div>
            <Snackbar open={errorService} autoHideDuration={6000} onClose={() => setErrorService(false)}>
                <Alert
                    variant="filled"
                    severity="warning"
                    onClose={() => setErrorService(false)}
                >
                    Lo sentimos, por el momento el servicio no se encuentra disponible
                </Alert>
            </Snackbar>

            <Grid container direction="column" justify="center" alignItems="center">
                <br />

                <Oferta datos={props} colorOne="#FF9F01" colorTwo="#FA4616" />

                <br />

                <Typography color='primary' align="center">
                    <TextHeader>Progreso de mi solicitud</TextHeader>
                </Typography>

                <br />

                <Padding>
                    <Typography >
                        Tienes acciones pendientes en tu proyecto de crédito. Visita cada paso para completarlo.
                    </Typography>
                </Padding>

                <br />

                {
                    tasks.length === 0
                        ?
                        <CircularProgress />
                        :
                        <CardElevation>
                            {
                                tasks.map((task, index) => {
                                    // const status = getSpecialStatusCase(task.status, task.kind) || (['new','needscorrection'].includes(task.status?.toLowerCase()) ? flujoState.tubo?.[task.kind]?.toLowerCase() || task.status?.toLowerCase() : task.status?.toLowerCase())
                                    // const status = task.status
                                    // const taskStatus = task.status === 'action_new' ? 'new' : task.status
                                    let taskStatus, descripcion;
                                    taskStatus = (task.status.toUpperCase() === "ACTION_NEW") ? 'new' : task.status
                                    descripcion = task.description
                                    if (pensionados && task.kind === "DefineBankAccount" && task.status === 'error') {
                                        taskStatus = 'new'
                                        descripcion = ''
                                    }
                                    if (pensionados && task.kind === "VerifyBankAccount" && taskStatus !== "inprogress") {
                                        descripcion = ''
                                    }
                                    return (
                                        <CardProgres
                                            key={task.kind}
                                            firstItem={index === 0}
                                            lastItem={index + 1 === tasks.length}
                                            icon={
                                                taskStatus?.toUpperCase() === 'NEW'
                                                    ?
                                                    getTaskIcon(task.kind)
                                                    :
                                                    getTaskStatusIcon(taskStatus)
                                            }
                                            title={task.title}
                                            body={<>{descripcion}</>}
                                            textAction={getTaskActionText(task.kind)}
                                            status={taskStatus?.toLowerCase()}
                                            onChange={() => navigate(`${former ? getTaskUrlFormer(task.kind) : getTaskUrl(task.kind)}`)}
                                        />
                                    )
                                })
                            }
                        </CardElevation>
                }

                <br />

                <ButtonDegrade
                    id="tu-proceso-finalizar-prestamo-button"
                    icon={ArrowForwardIcon}
                    textButton="FINALIZAR MI CRÉDITO"
                    onClick={nextPage}
                    disabled={!['APPROVED', 'REJECTED'].includes(status?.toUpperCase())}
                />

                <br />

                <CardElevation>
                    <ImageContainer>
                        <CarouselImg filename="3.svg" company={pageContext.company} />
                    </ImageContainer>
                </CardElevation>

                <br />
            </Grid>

            <ModalJumio title="Error de verificación" errorCode={errorCode} open={modal} onChange={() => { closeModal(false) }} />
        </div>
    )
}

TuProceso.propTypes = {
    image: PropTypes
}

export default TuProceso